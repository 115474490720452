/*
    Block - Thank you CSS
*/
@import "./../../styles/_global-variable.scss";
@import "./../../styles/_mixin.scss";

// inline variable
// ...

.detail-banner .title-wrap {
    width: 53%;
    @include break-point("", $global-width-mobile) {
        width: 100%;
    }
}
.page-content > section.confirm-block {
    .section-spacing {
        padding: 100px 210px;
        @include break-point("", $global-width-mobile) {
            padding: 30px 20px;
        }
    }
}
.confirm-block {
    #formCaptcha {
        margin-top: 30px;
    }
    #eNewsSubscriptionForm {
        margin-top: 30px;
    }
    text-align: center;

    > .wrap {
        overflow: hidden;
    }

    .form-container {
        > .wrap {
            @include break-point("", $global-width-mobile) {
                padding: 20px;
            }
        }
    }

    .message-wrap {
        background: #fff;
        border: 1px solid rgba(165, 172, 185, 0.37);

        .wrap {
            padding: 100px;
            overflow: hidden;
            @include break-point("", $global-width-mobile) {
                padding: 20px;
            }
        }
        .section-title {
            padding-bottom: 30px;
        }
        .desc {
            padding-bottom: 40px;
        }
        .btn-applynow {
            display: block;
            float: none;
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
    .form-section {
        .form-row {
            text-align: left;
        }
        .col4,
        .col2 {
            @include break-point("", $global-width-mobile) {
                width: 100% !important;
                float: none !important;
            }
        }
        .form-input-text {
            @include break-point("", $global-width-mobile) {
                margin-bottom: 30px;
                input {
                    margin-bottom: 20px;
                }
            }
        }
        input[type="text"] {
            background: none;
        }
        .form-checkbox-wrap {
            font-size: $global-section-desc;
            color: $global-grid-desc-blue-color;
            > label {
                margin-bottom: 20px;
                display: block;
            }
            .form-checkbox {
                input,
                label {
                    vertical-align: middle;
                }
                input {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    outline-color: transparent;
                    vertical-align: middle;
                    @include break-point("", $global-width-mobile) {
                        float: left;
                    }
                }
                label {
                    font-size: $global-section-desc;
                    color: $global-grid-desc-blue-color;
                    padding-left: 20px;
                    @include break-point("", $global-width-mobile) {
                        font-size: 1.6rem;
                        display: block;
                        padding-left: 40px;
                    }
                }
            }
        }
        .btn-applynow.submit {
            font-size: 1.8rem;
            @include break-point("", $global-width-mobile) {
                margin: 50px 0 10px 0;
                width: 100%;
                font-size: 1.6rem;
            }
        }
        .btn-applynow.reset {
            margin-left: 25px;
            @include break-point("", $global-width-mobile) {
                width: 100%;
                margin: 8px 0;
            }
        }
        .form-dropdown {
            position: relative;
            padding-right: 20px;
            vertical-align: middle;
            @include break-point("", $global-width-mobile) {
                padding-right: 0;
                margin-bottom: 30px;
            }
            .dropdown-item {
                position: relative;
            }
            label {
                width: auto;
                height: auto;
                z-index: 1;
                opacity: 1;
                font-size: inherit;
                position: relative;
                color: #626a73;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 1.8rem;
                font-weight: bold;
                @include break-point("", $global-width-mobile) {
                    font-size: 1.6rem;
                }
            }
            .btn-arrow {
                width: 26px;
                height: 20px;
                display: block;
                font-size: 1.6rem;
                line-height: 1;
                margin: auto;
                position: absolute;
                top: 13px;
                right: 0;
                bottom: auto;
            }

            &.active {
                .btn-arrow {
                    color: #fff;
                    margin-top: 14px;
                    @include transform(rotate(-180deg));
                    z-index: 1;
                }
            }
        }
    }
}
